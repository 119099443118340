var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", [_vm._v(_vm._s(_vm.value.text))]),
      _c("el-tooltip", { attrs: { effect: "dark", content: "重置人数" } }, [
        _c("i", {
          staticClass: "el-icon-refresh-left my-icon",
          attrs: { id: "icon" },
          on: {
            click: function ($event) {
              return _vm.reset($event, _vm.value)
            },
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }