<template>
    <span class="component-mult-input">
    
        <component :is="dragToSort ? 'draggable' : 'div'"
                   :class="dragToSort ? 'draggable' : ''"
                   v-model="list"
                   @start="isDragging = true"
                   @end="isDragging = false">

            <el-row v-for="(item, index) in list">
                <input type="hidden" v-model="item.key" v-if="params.hideKey">
                <el-col :span="8" style="padding-right: 10px;" v-else>
                    <el-input v-model="item.key" :placeholder="params.keyDesc"></el-input>
                </el-col>
                <el-col :span="10" style="padding-right: 10px;">
                    <el-input v-model="item.val" :placeholder="params.valDesc" :disabled="params.valDisabled"></el-input>
                </el-col>
                <template v-if="!params.detail">
                    <el-col :span="6" v-if="!params.detail">
                        <el-button size="mini" type="error" plain icon="el-icon-delete" @click="delItem(item, index)"></el-button>
                    </el-col>
                </template>
                <template v-else>
                    <br>
                    <div style="margin-top: 10px;">
                        <ImageUpload v-model="item.image" :drag="true" />
                    </div>
                    <div style="margin-top: 10px;">
                        <el-input v-model="item.desc" placeholder="请输入作者简介" :rows="4" type="textarea" />
                    </div>
                    <div style="margin-top: 10px;">
                        <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="delItem(item, index)">删除嘉宾</el-button>
                    </div>
                    <el-divider></el-divider>
                </template>
            </el-row>
        </component>

        <el-autocomplete
                v-model="search_title"
                :fetch-suggestions="querySearchAsync"
                :placeholder="params.placeholder"
                @select="handleSelect"
                @blur="handleBlur"
                @keyup.native="handleKeyup"
                v-if="params.url"
        ></el-autocomplete>
        
        <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addItem()" v-else></el-button>
        
        <b class="drag-to-sort" v-if="dragToSort">按住可进行拖拽排序</b>
        
    </span>
</template>

<script>
import draggable from 'vuedraggable'; // 拖动插件

export default {
    name: 'MultInput',
    components: { draggable },
    props: {
        value: [String, Object, Array],
        // 其他参数
        params: {
            type: Object,
            default: () => {
                return {};
            },
        },
        // 拖动排序
        dragToSort: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            list: [{key:'',val:''}],
            search_title: '',
        }
    },
    watch: {
        value: {
            handler(nval) {
                if( nval ){
                    let arr = JSON.parse(nval);
                    this.list = arr ? arr : this.list;
                }
            },
            deep: true,
            immediate: true,
        },
        list: {
            handler(nval) {
                this.$emit('input', JSON.stringify(nval));
            },
            deep: true,
            immediate: true,
        },
        params: {
            handler(nval) {
                nval.keyDesc = nval.keyDesc ? nval.keyDesc : '键';
                nval.valDesc = nval.valDesc ? nval.valDesc : '值';
                nval.placeholder = nval.placeholder ? nval.placeholder : '请输入内容';
            },
            deep: true,
            immediate: true,
        },
        
    },
    methods: {
        addItem(){
            this.list.push({key:'',val:''});
        },
        delItem(item, index){

            this.$confirm('确认要删除?', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.list.splice(index, 1);
            });
        },
        querySearchAsync(queryString, cb) {
            
            this.request.post(this.params.url, { name: this.search_title, title: this.search_title }).then((res)=>{
                if( res.data ){
                    let results = [];
                    res.data.forEach(item => {
                        item.value = item.name ? item.name : item.title;
                        results.push(item);
                    });
                    cb(results);
                }
            });
        },
        handleSelect(item){
            
            // 判断是否已关联
            let check = true;
            this.list.forEach(one => {
                if( one.key==item.id && one.val==item.value ){
                    this.$message({
                        type: 'warning',
                        message: '已添加',
                    });
                    check = false;
                }
            });
            // 未关联, 关联上
            if( check ){
                
                this.list.forEach(one => {
                    if( check ){
                        if( one.key=='' && one.val=='' ){
                            one.key = item.id;
                            one.val = item.value;

                            // 添加图片和简介
                            if( this.params.detail ){
                                one.image = item.image;
                                one.desc = item.description;
                            }

                            check = false;
                        }
                    }
                });
                if( check ){
                    let one = {
                        key: item.id,
                        val: item.value,
                    }

                    // 添加图片和简介
                    if( this.params.detail ){
                        one.image = item.image;
                        one.desc = item.description;
                    }
                    
                    this.list.push(one);
                }
                
                this.$message({
                    type: 'success',
                    message: '添加成功',
                });
                
                this.search_title = '';
            }
        },
        handleBlur(){
            // this.$emit('sendToParent', this.search_title);
        },
        handleKeyup(event){
            let param = {
                keyCode: event.keyCode,
                content: this.search_title,
            }
            this.$emit('sendToParent', param);
        },
        // 父组件传参
        doSth(params){
            if( params.clear ){
                this.search_title = '';
            }
        },
    },
}
</script>
<style scoped lang="scss">
.el-row{
    margin-bottom: 10px;
}
.draggable:hover{
    cursor: move;
}
.drag-to-sort{
    color: #f56c6c;
    margin-left: 20px;
}
</style>
