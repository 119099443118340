<template>
    <div>
        <span ref="span">{{text}}</span>
        <el-input v-model="text" v-on:blur="handleChangeBlur($event, value)" ref="input" class="custom-input"></el-input>
        <i id="icon" class="el-icon-edit my-icon" @click="prepHandleChangeBlur($event)"></i>
    </div>
</template>

<script>
    export default {
        name: 'Custom',
        props: {
            value: {
                required: true,
                type: Object,
            },
        },
        data(){
            return {
                text: '',
                input: '',
                span: '',
            }
        },
        watch: {
            value: {
                handler(nval) {
                    this.input = this.$refs.input;
                    this.span  = this.$refs.span;
                    this.text  = nval.val;
                },
                immediate: true,
                deep: true,
            },
        },
        methods: {
            handleChangeBlur(event, val) {
                val.val = this.text;
                if( !this.input ){
                    this.input = this.$refs.input;
                    this.span  = this.$refs.span;
                }
                this.span.hidden = false;
                this.input.$el.style.display = 'none';
                this.$emit('customChange', val);
            },
            prepHandleChangeBlur(event){
                if( !this.input ){
                    this.input = this.$refs.input;
                    this.span  = this.$refs.span;
                }
                this.input.$el.style.display = 'inline-block';
                this.input.$el.focus();
                this.span.hidden  = true;
            },
        },
    }
</script>

<style rel="scss" scoped>
.my-icon{
    cursor: pointer;
    color: #409eff;
    margin-left: 4px;
}
::v-deep .custom-input{
    display: none;
}
</style>
