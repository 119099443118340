var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.type == "url"
        ? _c("el-upload", {
            ref: "upload",
            staticStyle: { display: "none" },
            attrs: {
              name: "file",
              action: "#",
              "before-upload": _vm.handleBeforeUpload,
              "on-error": _vm.handleUploadError,
              "http-request": _vm.handleUpload,
              "show-file-list": false,
              headers: _vm.headers,
            },
          })
        : _vm._e(),
      _c("div", { ref: "editor", staticClass: "editor", style: _vm.styles }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }