var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c(
        "router-link",
        { staticClass: "home-link", class: _vm.active, attrs: { to: "/" } },
        [_c("i", { staticClass: "el-icon-s-home home-ico" })]
      ),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "所属角色： " + _vm.groups_name,
                        placement: "bottom",
                      },
                    },
                    [
                      _c("span", { staticClass: "text-mini" }, [
                        _vm._v(" " + _vm._s(_vm.name || "管理员")),
                      ]),
                    ]
                  ),
                  _c("img", {
                    staticClass: "user-avatar",
                    attrs: { src: _vm.avatar },
                  }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/staff/profile" } },
                    [
                      _c("el-dropdown-item", [
                        _c("i", { staticClass: "el-icon-user" }),
                        _c("span", [_vm._v("账户中心")]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-switch-button" }),
                      _c("span", [_vm._v("退出系统")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tooltip",
            {
              staticClass: "item",
              attrs: {
                effect: "dark",
                content: "退出登录",
                placement: "bottom",
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-right logout-btn",
                on: { click: _vm.logout },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }