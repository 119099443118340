var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "component-baidu-map" }, [
    _c(
      "div",
      { staticClass: "outer-box" },
      [
        _c(
          "el-tooltip",
          { attrs: { content: "点击上方编辑按钮方可修改定位" } },
          [!_vm.edit ? _c("div", { staticClass: "inner-cover" }) : _vm._e()]
        ),
        _c("div", {
          staticStyle: { width: "500px", height: "500px" },
          attrs: { id: "container" },
        }),
        _c("span", { attrs: { id: "result" } }, [_vm._v(_vm._s(_vm.address))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }