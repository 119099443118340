import { render, staticRenderFns } from "./reset.vue?vue&type=template&id=805c01e8&scoped=true&"
import script from "./reset.vue?vue&type=script&lang=js&"
export * from "./reset.vue?vue&type=script&lang=js&"
import style0 from "./reset.vue?vue&type=style&index=0&id=805c01e8&rel=scss&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805c01e8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\phpstudy\\PHPTutorial\\WWW\\xyg-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('805c01e8')) {
      api.createRecord('805c01e8', component.options)
    } else {
      api.reload('805c01e8', component.options)
    }
    module.hot.accept("./reset.vue?vue&type=template&id=805c01e8&scoped=true&", function () {
      api.rerender('805c01e8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/QuickAdminTable/reset.vue"
export default component.exports