<template>
    <div>
        <el-switch :value="status"
                   :active-value="activeValue"
                   :inactive-value="inactiveValue"
                   :active-text="activeText"
                   :inactive-text="inactiveText"
                   @change="statusChange"></el-switch>
    </div>
</template>

<script>
export default {
    name: 'QuickAdminSwitch',
    props: {
        value: {
            required: true,
            type: Number | Object,
        },
    },
    data(){
        return {
            status: 0,
            activeValue: 1,
            inactiveValue: 0,
            activeText: '',
            inactiveText: '',
        }
    },
    methods: {
        statusChange(val) {
            let text = val === this.inactiveValue ? '关闭' : '开启';
            let msg  = '确认要' + text + '吗?';
            msg = this.value.confirmMsg ? this.value.confirmMsg : msg;
            this.$confirm(msg, '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$emit('statusChange', val);
            }).then(() => {
                this.msgSuccess('操作成功');
            }).catch(() => {
                val = val === this.inactiveValue ? this.activeValue : this.inactiveValue;
            });
        },
    },
    watch:{
        value: {
            handler (nval, oval) {
                if( isNaN(nval) ){
                    this.status = nval.status;
                    this.activeText = nval.activeText ? nval.activeText : '';
                    this.inactiveText = nval.inactiveText ? nval.inactiveText : '';
                    this.activeValue = nval.activeValue ? nval.activeValue : 1;
                    this.inactiveValue = nval.inactiveValue ? nval.inactiveValue : 0;
                } else {
                    this.status = nval;
                }
            },
            immediate: true,
        },
    },
}
</script>
