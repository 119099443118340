var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.value
        ? _vm._l(_vm.value.split(","), function (item, index) {
            return _c("el-image", {
              key: index,
              staticClass: "curd-image",
              attrs: {
                src: item + "-w450hauto",
                fit: "cover",
                "preview-src-list": [item],
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }