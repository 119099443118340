<template>
    <div class="component-baidu-map">
    
        <div class="outer-box">
    
            <el-tooltip content="点击上方编辑按钮方可修改定位">
                <div class="inner-cover" v-if="!edit"></div>
            </el-tooltip>
    
            <div id="container" style="width: 500px; height: 500px;"></div>
    
            <span id="result">{{address}}</span>
            
        </div>

    </div>
</template>


<script>

// 原js: http://api.map.baidu.com/api?v=2.0&ak=2ellUlvsqnKzoG7Y7LirHyQgo5as6wWX;
// 文档: https://mapopen-pub-jsapi.bj.bcebos.com/jsapi/reference/jsapi_webgl_1_0.html
import '@/baidu.map.js';


export default {
    name: 'MultInput',
    props: {
        value: [String, Object, Array],
        // 其他参数
        params: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            list: [{key:'',val:''}],
            edit: false,
            address: '',
            lat: '',
            lng: '',
        }
    },
    updated(){

        this.$nextTick(() => {
            
            // 创建地图实例
            let map = new BMap.Map("container");

            // 坐标
            let lat = this.lat ? this.lat : 23.132781;
            let lng = this.lng ? this.lng : 113.321377;

            // 创建点坐标
            let point = new BMap.Point(lng, lat);

            // 初始化地图，设置中心点坐标和地图级别
            map.centerAndZoom(point, 20);
            map.setCenter(point);

            // 创建标注
            let marker = new BMap.Marker(point);

            // 将标注添加到地图中
            map.addOverlay(marker);
            
            // 开启鼠标滚轮缩放
            map.enableScrollWheelZoom();
            
            //单击获取点击的经纬度
            let _this = this;
            map.addEventListener("click",function(e){
                map.clearOverlays();

                lng = e.point.lng;
                lat = e.point.lat;

                // 定义标注坐标
                let point = new BMap.Point(lng, lat);
                
                // 创建标注
                let marker = new BMap.Marker(point);

                // 将标注添加到地图中
                map.addOverlay(marker);

                _this.$emit('sendToParent', {location: lat + ',' + lng});

            });

        });
    },
    watch: {
        value: {
            handler(nval) {
                if( nval ){
                    [this.lat, this.lng] = nval.split(',');
                }
            },
            deep: true,
            immediate: true,
        },
        params: {
            handler(nval) {
                // console.log(nval)
                if( nval.location ){
                    [this.lat, this.lng] = nval.location.split(',');
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        // 父组件传参
        doSth(params){
            if( params.edit ){
                this.edit = true;
            }
            if( params.address ){
                this.address = params.address;
            }
        },
    },
}
</script>

<style scoped lang="scss">
    .component-baidu-map{
        position: relative;
    }
    #result {
        position: absolute;
        top: 10px;
        left: 10px;
        min-width: 250px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #fff;
        box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
        border-radius: 7px;
    }
    ::v-deep .anchorBL{
        display: none;
    }
    ::v-deep .BMap_cpyCtrl{
        display: none;
    }
    .outer-box{
        position: relative;
        width: fit-content;
        height: fit-content;
    }
    .inner-cover{
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: not-allowed;
        z-index: 999;
    }

</style>
