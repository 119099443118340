var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "component-upload-image" },
    [
      _vm.dragToSort
        ? _c(
            "ul",
            { staticClass: "el-upload-list el-upload-list--picture-card" },
            [
              _c(
                "draggable",
                {
                  on: {
                    start: function ($event) {
                      _vm.isDragging = true
                    },
                    end: function ($event) {
                      _vm.isDragging = false
                    },
                    update: _vm.updateList,
                  },
                  model: {
                    value: _vm.fileList,
                    callback: function ($$v) {
                      _vm.fileList = $$v
                    },
                    expression: "fileList",
                  },
                },
                _vm._l(_vm.fileList, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: item + index,
                      staticClass: "el-upload-list__item is-success",
                    },
                    [
                      _c("img", {
                        staticClass: "el-upload-list__item-thumbnail",
                        attrs: { src: item.url },
                      }),
                      _c(
                        "label",
                        { staticClass: "el-upload-list__item-status-label" },
                        [
                          _c("i", {
                            staticClass: "el-icon-upload-success el-icon-check",
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "el-upload-list__item-actions drag-to-sort",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-preview" },
                            [
                              _c("i", {
                                staticClass: "el-icon-zoom-in",
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePictureCardPreview(item)
                                  },
                                },
                              }),
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-delete" },
                            [
                              _c("i", {
                                staticClass: "el-icon-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRemove(item, _vm.fileList)
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-upload",
        {
          class: { hide: this.fileList.length >= this.limit },
          attrs: {
            action: "#",
            name: "file",
            "list-type": "picture-card",
            "before-upload": _vm.handleBeforeUpload,
            limit: _vm.limit,
            disabled: _vm.disabled,
            drag: _vm.drag,
            "on-error": _vm.handleUploadError,
            "on-exceed": _vm.handleExceed,
            "on-remove": _vm.handleRemove,
            "on-preview": _vm.handlePictureCardPreview,
            "show-file-list": _vm.isShowFileList,
            headers: _vm.headers,
            "file-list": _vm.fileList,
            multiple: _vm.multiple,
            dragToSort: _vm.dragToSort,
            "on-change": _vm.handleChange,
            "http-request": _vm.handleUpload,
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _vm.showTip
        ? _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [
              _vm._v(" 请上传 "),
              _vm.fileSize
                ? [
                    _vm._v(" 大小不超过 "),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v(_vm._s(_vm.fileSize) + "MB"),
                    ]),
                  ]
                : _vm._e(),
              _vm.fileType
                ? [
                    _vm._v(" 格式为 "),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v(_vm._s(_vm.fileType.join("/"))),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" 的文件 "),
              _vm.drag
                ? [
                    _c("br"),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v("点击按钮"),
                    ]),
                    _vm._v("或"),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v("拖拽图片"),
                    ]),
                    _vm._v("到本区域上传 "),
                  ]
                : _vm._e(),
              _vm.dragToSort
                ? [
                    _c("br"),
                    _c("b", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v("按住图片"),
                    ]),
                    _vm._v("可进行拖拽排序 "),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "预览",
            width: "800",
            "append-to-body": "",
            "before-close": _vm.closePreview,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              display: "block",
              "max-width": "100%",
              margin: "0 auto",
            },
            attrs: { src: _vm.dialogImageUrl },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }