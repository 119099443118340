<template>
    <div>
        <el-upload name="file" action="#" style="display: none" ref="upload"
            :before-upload="handleBeforeUpload"
            :on-error="handleUploadError"
            :http-request="handleUpload"
            :show-file-list="false"
            :headers="headers"
            v-if="this.type == 'url'"
        >
        </el-upload>
        <div class="editor" ref="editor" :style="styles"></div>
    </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import '../../assets/css/quillEditor.css';
import { getToken } from '@/utils/auth';
import htmlEditButton from 'quill-html-edit-button';
Quill.register({
    "modules/htmlEditButton": htmlEditButton
})

const OSS    = require('ali-oss');
const client = new OSS({
    region: 'oss-cn-guangzhou',
    accessKeyId: 'LTAI5tCs7UJuQcBnDqrE9qiA',
    accessKeySecret: 'k3Rp4oBDKR2zIosIKTOF9vCNJhshJE',
    bucket: 'xygallery'
});

// 引入字体
let fonts = [
    "SimSun",
    "SimHei",
    "Microsoft-YaHei",
    "KaiTi",
    "SongTi",
    "FangSong",
    "Arial",
    "Times-New-Roman",
    "sans-serif",
];

// 引入字号
let sizes = [false, "16px", "18px", "20px", "22px", "26px", "28px", "30px"];

export default {
    name: 'Editor',
    props: {
        /* 编辑器的内容 */
        value: {
            type: String | Number,
            default: '',
        },
        /* 高度 */
        height: {
            type: Number,
            default: null,
        },
        /* 最小高度 */
        minHeight: {
            type: Number,
            default: 300,
        },
        /* 只读 */
        readOnly: {
            type: Boolean,
            default: false,
        },
        // 上传文件大小限制(MB)
        fileSize: {
            type: Number,
            default: 50,
        },
        /* 类型（base64格式、url格式） */
        type: {
            type: String,
            default: 'url',
        },
    },
    data() {
        return {
            uploadUrl: process.env.VUE_APP_BASE_API + '/admin/ajax/upload', // 上传的图片服务器地址
            headers: {
                token: getToken(),
            },
            Quill: null,
            currentValue: '',
            options: {
                theme: 'snow',
                bounds: document.body,
                debug: 'warn',
                modules: {

                    htmlEditButton: {
                        // logging: false,
                        debug: false,
                        // Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                        msg: "在这里修改HTML代码，点击完成将替换编辑器的内容",
                        // Text to display in the OK button, default: Ok,
                        okText: "完成",
                        // Text to display in the cancel button, default: Cancel
                        cancelText: "取消",
                        // Text to display in the toolbar button, default: <>
                        buttonHTML: '<svg width="20" height="20" viewBox="0 0 40 40" fill="none"><circle cx="20" cy="20" r="20" fill="#444"></circle><circle cx="20" cy="20" r="20" fill="#444"></circle><path d="M14 14L9 20L14 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M26 14L31 20L26 26" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22.5 17L17.5 23.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
                        // Text to display as the tooltip for the toolbar button, default: Show HTML source
                        buttonTitle: "Show HTML source",
                        // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                        syntax: false,
                        // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                        prependSelector: 'div#myelement',
                        // The default mod
                        editorModules: {}
                    },
                    // 工具栏配置
                    toolbar: {
                        container: [
                            ['undo', 'redo', 'clean'], // 撤销 重做 清除文本格式
                            [{ font: fonts }], // 字号
                            [{ size: sizes }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            // ['blockquote', 'code-block'], // 引用  代码块
                            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                            [{ indent: '-1' }, { indent: '+1' }], // 缩进
                            [{ align: [] }], // 对齐方式
                            ['link', 'image', 'video'], // 链接、图片、视频
                            [{ 'script': 'sub'}, { 'script': 'super' }], // 下标 上标
                        ],
                        handlers:{
                            'undo' : this.myUndo,
                            'redo' : this.myRedo,
                        },
                    },
                },
                placeholder: '请输入内容',
                readOnly: this.readOnly,
            },
            uploadType: '',
        }
    },
    computed: {
        styles() {
            const style = {}
            if (this.minHeight) {
                style.minHeight = `${this.minHeight}px`
            }
            if (this.height) {
                style.height = `${this.height}px`
            }
            return style
        },
    },
    watch: {
        value: {
            handler(val) {
                if (val !== this.currentValue) {
                    this.currentValue = val === null ? '' : val;
                    if (this.Quill) {
                        this.Quill.pasteHTML(this.currentValue);
                    }
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        this.Quill = null
    },
    methods: {
        init() {

            // 设置白名单
            let Font = Quill.import('formats/font');
            Font.whitelist = fonts;
            Quill.register(Font, true);
            
            let Size = Quill.import('formats/size');
            Size.whitelist = sizes;
            Quill.register(Size, true);
            
            // 添加撤销, 重做按钮
            let icons = Quill.import('ui/icons');
            icons["undo"] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>`;
            icons["redo"] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>`;
            
            // 更改清除格式按钮
            icons["clean"] = `<svg xmlns="http://www.w3.org/2000/svg" style="width: 17px;" viewBox="0 0 21.14 18.97"><g><g><path d="M14.67,14.37v0L20.4,8.64a2.55,2.55,0,0,0,0-3.59L16.09.74a2.55,2.55,0,0,0-3.59,0L.74,12.49a2.56,2.56,0,0,0,0,3.6L3.5,18.84h0l0,0h0l0,0h0l0,0h0l0,0H16.9a.44.44,0,1,0,0-.88H11l3.7-3.71Zm-5,3.72H4L1.36,15.46a1.63,1.63,0,0,1-.48-1.17,1.65,1.65,0,0,1,.48-1.17L7.08,7.4l6.66,6.65ZM7.7,6.78l5.42-5.42A1.65,1.65,0,0,1,14.29.88a1.63,1.63,0,0,1,1.17.48l4.31,4.31a1.66,1.66,0,0,1,.49,1.17A1.64,1.64,0,0,1,19.77,8l-5.41,5.41Z"/></g></g></svg>`;
            const editor = this.$refs.editor;
            this.Quill = new Quill(editor, this.options);

            // 在需要时手动启用获取焦点
            this.$refs.editor.addEventListener('click', () => {
                this.Quill.enable();
            });

            // 禁用自动获取焦点
            this.Quill.disable();
            
            // 如果设置了上传地址则自定义图片上传事件
            if (this.type == 'url') {
                const toolbar = this.Quill.getModule('toolbar');
                toolbar.addHandler('image', value => {
                    this.uploadType = 'image';
                    if (value) {
                        this.$refs.upload.$children[0].$refs.input.click();
                    } else {
                        this.quill.format('image', false)
                    }
                });
                toolbar.addHandler('video', value => {
                    this.uploadType = 'video';
                    if (value) {
                        this.$refs.upload.$children[0].$refs.input.click();
                    } else {
                        this.quill.format('video', false)
                    }
                });
            }
            this.Quill.pasteHTML(this.currentValue)
            this.Quill.on('text-change', (delta, oldDelta, source) => {
                if( !this.$refs.editor ){
                    return false;
                }
                let html = this.$refs.editor.children[0].innerHTML;
                let search  = 'class="ql-video"';
                let replace = 'style="width:100%;aspect-ratio:16/9;"';
                if( html.indexOf(search)!==-1 ){
                    html = html.replace(search, replace);
                    this.$refs.editor.children[0].innerHTML = html;
                }
                const text = this.Quill.getText();
                const quill = this.Quill;
                this.currentValue = html;
                this.$emit('input', html);
                this.$emit('on-change', { html, text, quill });
            });
            this.Quill.on('text-change', (delta, oldDelta, source) => {
                this.$emit('on-text-change', delta, oldDelta, source);
            });
            this.Quill.on('selection-change', (range, oldRange, source) => {
                this.$emit('on-selection-change', range, oldRange, source);
            });
            this.Quill.on('editor-change', (eventName, ...args) => {
                this.$emit('on-editor-change', eventName, ...args);
            });

        },
        // 上传前校检格式和大小
        handleBeforeUpload(file) {
            // 校检文件大小
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize
                if (!isLt) {
                    this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`)
                    return false
                }
            }
            return true
        },
        // 自定义上传(阿里云)
        handleUpload(option){
            console.log(this.uploadType);

            let file = option.file;
            let ext  = file.name.slice(file.name.lastIndexOf('.') + 1);
            let name = Math.random().toString(36).substring(2) + '.' + ext;
            
            let check   = false;
            let errMsg  = 'error';
            let typeArr = ['png', 'jpg', 'jpeg', 'gif'];
            if( this.uploadType=='image' ){
                errMsg  = '图片格式错误';
            } else if ( this.uploadType=='video' ) {
                typeArr = ['avi','mp4','m4v','mkv'];
                errMsg  = '视频格式错误';
            }
            typeArr.forEach(item => {
                if( item==ext ){
                    check = true;
                }
            });
            if( !check ){
                this.$message.error(errMsg);
                return false;
            }

            const progress = (p, cpt) => {
                // 分片上传的断点信息 checkpoint
                // console.log(cpt);

                // Object的上传进度, 1代表完成
                // console.log(p);
                if( p==1 ){
                    
                    let url = process.env.VUE_APP_ALIOSS + name;

                    // 获取富文本组件实例
                    const quill = this.Quill;
                    // 获取光标所在位置
                    const index = quill.getSelection().index;
                    // 插入图片 res.data.url为服务器返回的图片地址
                    quill.insertEmbed(index, this.uploadType, url);
                    // 调整光标到最后
                    quill.setSelection(index + 1);
                }
            }

            // 开始分片上传。
            async function multipartUpload(name, file) {

                try {
                    return await client.multipartUpload(name, file, {
                        progress,
                    });
                } catch (e) {
                    // 捕获超时异常。
                    if (e.code === 'ConnectionTimeoutError') {
                        console.log('TimeoutError');
                    }
                    console.log(e);
                }
            }

            multipartUpload(name, file);
        },
        handleUploadError() {
            this.$message.error('图片插入失败')
        },
        myUndo(){
            this.Quill.history.undo();
        },
        myRedo(){
            this.Quill.history.redo();
        },
    },
}
</script>

<style>
.editor,
.ql-toolbar {
    white-space: pre-wrap !important;
    line-height: normal !important;
}
.quill-img {
    display: none;
}
.ql-snow .ql-tooltip[data-mode='link']::before {
    content: '请输入链接地址:';
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode='video']::before {
    content: '请输入视频地址:';
}
.ql-snow .ql-tooltip[data-mode='video'],
.ql-snow .ql-tooltip[data-mode='link'] {
    left: 0 !important;
    top: 0 !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
    content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
    content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
    content: '32px';
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
    content: '标题1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
    content: '标题2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
    content: '标题3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
    content: '标题4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
    content: '标题5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
    content: '标题6';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
    content: '衬线字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
    content: '等宽字体';
}
.ql-snow .ql-editor{
    height: 20vw;
}
</style>
