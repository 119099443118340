<template>
    <div class="component-upload-image">
        <ul class="el-upload-list el-upload-list--picture-card" v-if="dragToSort">
            <draggable v-model="fileList"
                       @start="isDragging = true"
                       @end="isDragging = false"
                       @update="updateList">
        
                <li class="el-upload-list__item is-success"
                    v-for="(item, index) in fileList"
                    :key="item + index">
                    
                    <img :src="item.url"  class="el-upload-list__item-thumbnail"/>
                    <label class="el-upload-list__item-status-label">
                        <i class="el-icon-upload-success el-icon-check"></i>
                    </label>
                    <span class="el-upload-list__item-actions drag-to-sort">
                        <span class="el-upload-list__item-preview">
                            <i class="el-icon-zoom-in" @click="handlePictureCardPreview(item)"></i>
                        </span>
                        <span class="el-upload-list__item-delete">
                            <i class="el-icon-delete" @click="handleRemove(item, fileList)"></i>
                        </span>
                    </span>
                </li>
            </draggable>
        </ul>
        
        <el-upload action="#" name="file" list-type="picture-card"
            :before-upload="handleBeforeUpload"
            :limit="limit"
            :disabled="disabled"
            :drag="drag"
            :on-error="handleUploadError"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            :on-preview="handlePictureCardPreview"
            :show-file-list="isShowFileList"
            :headers="headers"
            :file-list="fileList"
            :class="{ hide: this.fileList.length >= this.limit }"
            :multiple="multiple"
            :dragToSort="dragToSort"
            :on-change="handleChange"
            :http-request="handleUpload"
        >
            <i class="el-icon-plus"></i>
        </el-upload>

        <!-- 上传提示 -->
        <div class="el-upload__tip" slot="tip" v-if="showTip">
            请上传
            <template v-if="fileSize">
                大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
            </template>
            <template v-if="fileType">
                格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
            </template>
            的文件
            <template v-if="drag">
                <br><b style="color: #f56c6c">点击按钮</b>或<b style="color: #f56c6c">拖拽图片</b>到本区域上传
            </template>
            <template v-if="dragToSort">
                <br><b style="color: #f56c6c">按住图片</b>可进行拖拽排序
            </template>
        </div>

        <el-dialog :visible.sync="dialogVisible" title="预览" width="800" append-to-body :before-close="closePreview">
            <img :src="dialogImageUrl" style="display: block; max-width: 100%; margin: 0 auto" />
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth';
import draggable from 'vuedraggable'; // 拖动插件

const OSS    = require('ali-oss');
const client = new OSS({
    region: 'oss-cn-guangzhou',
    accessKeyId: 'LTAI5tCs7UJuQcBnDqrE9qiA',
    accessKeySecret: 'k3Rp4oBDKR2zIosIKTOF9vCNJhshJE',
    bucket: 'xygallery'
});

export default {
    components: { draggable },
    props: {
        value: [String, Object, Array],
        // 图片数量限制
        limit: {
            type: Number,
            default: 1,
        },
        // 大小限制(MB)
        fileSize: {
            type: Number,
            default: 5,
        },
        // 文件类型, 例如['png', 'jpg', 'jpeg']
        fileType: {
            type: Array,
            default: () => ['png', 'jpg', 'jpeg'],
        },
        // 是否显示提示
        isShowTip: {
            type: Boolean,
            default: true,
        },
        disabled: Boolean,
        // 拖拽上传
        drag: {
            type: Boolean,
            default: false,
        },
        // 拖动排序
        dragToSort: {
            type: Boolean,
            default: false,
        },
        // 其他参数
        params: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            hideUpload: false,
            uploadImgUrl: process.env.VUE_APP_BASE_API + '/admin/ajax/upload', // 上传的图片服务器地址
            headers: {
                token: getToken(),
            },
            fileList: [],
            arr: [],
            isShowFileList: true,
            multiple: false,
            listCount: 0,
            loading: undefined,
            fileErr: false,
        }
    },
    watch: {
        value: {
            handler(nval) {
                if (nval) {
                    // 首先将值转为数组
                    const list = Array.isArray(nval) ? nval : this.value.split(',')
                    // 然后将数组转为对象数组
                    this.fileList = list.map(item => {
                        if (typeof item === 'string') {
                            item = item.indexOf('-w450hauto')==-1 ? item +'-w450hauto' : item;
                            item = { name: item, url: item }
                        }
                        return item;
                    });

                    if( this.listCount==0 ){
                        this.listCount = this.fileList.length;
                    }
                } else {
                    this.fileList = []
                    return []
                }
            },
            deep: true,
            immediate: true,
        },
        
        limit:{
            handler(nval) {
                if (nval) {
                    this.multiple = nval>1 ? true : false;
                }
            },
            deep: true,
            immediate: true,
        },
        
        dragToSort:{
            handler(nval) {
                if (nval) {
                    if( this.multiple ){
                        this.isShowFileList = false;
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        // 是否显示提示
        showTip() {
            return this.isShowTip && (this.fileType || this.fileSize)
        },
    },
    mounted() {

        document.getElementsByClassName('el-upload-dragger').forEach(item => {
            if( item ){
                item.parentNode.style.border = 'none';
                item.parentNode.style.width  = 'fit-content';
            }
        });
        
    },
    methods: {
        // 删除图片
        handleRemove(file, fileList) {

            // 禁止操作时也禁止删除
            if( this.disabled ){
                return true;
            }

            this.listCount = fileList.length;
            const findex = this.fileList.map(f => f.name).indexOf(file.name);
            if( !this.fileErr ){
                this.fileList.splice(findex, 1);
                if( this.multiple ){
                    this.listCount--;
                }
            } else {
                this.fileErr = false;
            }

            // 返回 v-model的参数（关键）
            this.$emit('input', this.listToString(this.fileList));
            
        },
        // 上传前loading加载
        handleBeforeUpload(file) {

            let isImg = false;
            if (this.fileType.length) {
                let fileExtension = '';
                if (file.name.lastIndexOf('.') > -1) {
                    fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
                }
                isImg = this.fileType.some(type => {
                    if (file.type.indexOf(type) > -1) return true;
                    if (fileExtension && fileExtension.indexOf(type) > -1) return true;
                    return false;
                });
            } else {
                isImg = file.type.indexOf('image') > -1;
            }

            let check = true;
            if (!isImg) {
                this.$message.error(`文件格式不正确, 请上传${this.fileType.join('/')}图片格式文件!`);
                check = false;
            }
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize;
                if (!isLt) {
                    this.$message.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`);
                    check = false;
                }
            }
            
            if( check ){
                this.loading = this.loading ? this.loading : this.$loading({
                    lock: true,
                    text: '上传中',
                    background: 'rgba(0, 0, 0, 0.7)',
                });
            } else {
                this.fileErr = true;
                return false;
            }
        },
        // 文件个数超出
        handleExceed() {
            this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`)
        },
        // 上传失败
        handleUploadError() {
            this.$message({
                type: 'error',
                message: '上传失败',
            })
            this.loading.close();
        },
        // 预览
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url.replace('-w450hauto', '');
            this.dialogVisible = true;
        },
        // 关闭预览
        closePreview(){
            this.dialogImageUrl = '';
            this.dialogVisible = false;
        },
        // 对象转成指定字符串分隔
        listToString(list, separator) {
            let strs = ''
            separator = separator || ','
            for (const i in list) {
                strs += list[i].url + separator
            }
            return strs != '' ? strs.substr(0, strs.length - 1) : ''
        },
        // 拖拽更新数据
        updateList(e){
            // 返回 v-model的参数（关键）
            this.$emit('input', this.listToString(this.fileList));
        },
        handleChange(file){
            this.listCount++
        },
        // 自定义上传(阿里云)
        handleUpload(option){
            
            let file = option.file;
            let ext  = file.name.slice(file.name.lastIndexOf('.') + 1);
            let name = Math.random().toString(36).substring(2) + '.' + ext;
            
            const progress = (p, cpt) => {
                // 分片上传的断点信息 checkpoint
                // console.log(cpt);

                // Object的上传进度, 1代表完成
                // console.log(p);
                if( p==1 ){

                    this.fileList.push({
                        name: name,
                        url: process.env.VUE_APP_ALIOSS + name,
                    });
                    
                    console.log(this.fileList.length);
                    console.log(this.listCount);
                    
                    if( this.fileList.length==this.listCount ){
                        let _this = this;
                        setTimeout(() => {
                            // 返回 v-model的参数（关键）
                            _this.$emit('input', this.listToString(this.fileList));
                            _this.loading.close();
                            _this.$message.success('上传成功');
                        }, 500);
                    }
                }
            }

            // 开始分片上传。
            async function multipartUpload(name, file) {

                try {
                    return await client.multipartUpload(name, file, {
                        progress,
                    });
                } catch (e) {
                    // 捕获超时异常。
                    if (e.code === 'ConnectionTimeoutError') {
                        console.log('TimeoutError');
                    }
                    console.log(e);
                }
            }

            multipartUpload(name, file);
            
        }
    },
}
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
::v-deep .hide .el-upload--picture-card {
    display: none;
}
// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
    transition: all 0s;
}

::v-deep .el-list-enter,
.el-list-leave-active {
    opacity: 0;
    transform: translateY(0);
}
::v-deep .el-upload-dragger{
    height: 150px;
    margin-left: -1px;
}
.drag-to-sort{
    cursor: grab;
}
</style>
