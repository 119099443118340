<template>
    <div class="sidebar-logo-container" :class="{ collapse: collapse }" :style="{ backgroundColor: sideTheme === 'theme-dark' ? variables.menuBg : variables.menuLightBg }">
        <transition name="sidebarLogoFade">
            <router-link key="expand" class="sidebar-logo-link" to="/">
                <img v-if="logo" :src="logo" class="sidebar-logo" />
                <div class="sidebar-right">
                    <h1 class="sidebar-title">学有缉熙</h1>
                    <h2 class="sidebar-desc">XY GALLERY</h2>
                </div>
            </router-link>
        </transition>
    </div>
</template>

<script>
import variables from '@/assets/styles/variables.scss'

export default {
    name: 'SidebarLogo',
    props: {
        collapse: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        variables() {
            return variables
        },
        sideTheme() {
            return this.$store.state.settings.sideTheme
        },
    },
    data() {
        return {
            title: this.$store.state.permission.siteConfig.backend_title,
            logo: this.$store.state.permission.siteConfig.site_logo || require('@/assets/logo/logo.png'),
        }
    },
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
    opacity: 0;
}

.sidebar-logo-container {
    position: relative;
    width: 100%;
    background: #2b2f3a;
    text-align: center;
    overflow: hidden;

    & .sidebar-logo-link {
        height: 100%;
        width: 100%;
        padding-top: 20px;

        & .sidebar-logo {
            width: 60px;
            height: 60px;
            float: left;
            margin-left: 36px;
            margin-right: 10px;
            /*display: block;*/
            /*margin: 5px auto;*/
        }
        
        & .sidebar-right{
            float: left;
    
            & .sidebar-title {
                margin: 8px 0;
                color: #000;
                display: block;
                font-size: 20px;
                font-weight: 600;
            }
    
            & .sidebar-desc{
                color: #000;
                margin: 8px 0;
                display: block;
                font-size: 16px;
                font-weight: 300;
            }
        }
    }

    &.collapse {
        .sidebar-logo {
            margin-right: 0px;
        }
    }
}
</style>
