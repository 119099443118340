<template>
    <div class="navbar">
        <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

        <router-link class="home-link" to="/" :class="active">
            <i class="el-icon-s-home home-ico"></i>
        </router-link>
    
        <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav" />
        <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />

        <div class="right-menu">

            <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
                <div class="avatar-wrapper">
                    <el-tooltip class="item" effect="dark" :content="`所属角色： ${groups_name}`" placement="bottom">
                        <span class="text-mini"> {{ name || '管理员' }}</span>
                    </el-tooltip>
                    <img :src="avatar" class="user-avatar" />
                </div>
                <el-dropdown-menu slot="dropdown">
                    <router-link to="/staff/profile">
                        <el-dropdown-item>
                            <i class="el-icon-user" />
                            <span>账户中心</span>
                        </el-dropdown-item>
                    </router-link>
                    <el-dropdown-item divided @click.native="logout">
                        <i class="el-icon-switch-button" />
                        <span>退出系统</span>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
    
            <el-tooltip class="item" effect="dark" content="退出登录" placement="bottom">
                <i class="el-icon-right logout-btn" @click="logout"></i>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'

export default {
    components: {
        Breadcrumb,
        TopNav,
        Hamburger,
        Screenfull,
        SizeSelect,
        Search,
    },
    data(){
        return {
            active: this.$route.name==='Index' ? 'active' : '',
        }
    },
    computed: {
        ...mapGetters(['sidebar', 'avatar', 'device', 'groups_name', 'name']),
        setting: {
            get() {
                return this.$store.state.settings.showSettings
            },
            set(val) {
                this.$store.dispatch('settings/changeSetting', {
                    key: 'showSettings',
                    value: val,
                })
            },
        },
        topNav: {
            get() {
                return this.$store.state.settings.topNav
            },
        },
    },
    methods: {
        refreshSelectedTag() {
            this.$router.replace({
                path: '/redirect' + this.$route.path,
            })
        },
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar');
        },
        async logout() {
            this.$confirm('确定退出登录吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$store.dispatch('LogOut').then(() => {
                    location.href = '/index';
                })
            }).catch(() => {});
        },
    },
    watch: {
        $route(obj){
            this.active = obj.name==='Index' ? 'active' : '';
        }
    },
}
</script>

<style lang="scss" scoped>
.navbar {
    height: 60px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .hamburger-container {
        line-height: 46px;
        height: 100%;
        float: left;
        cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;
        padding: 5px 12px!important;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .topmenu-container {
        position: absolute;
        left: 50px;
    }

    .errLog-container {
        display: inline-block;
        vertical-align: top;
    }

    .right-menu {
        float: right;
        height: 100%;
        line-height: 50px;

        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #5a5e66;
            vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }

        .avatar-container {
            margin-right: 20px;

            .avatar-wrapper {
                margin-top: 5px;
                position: relative;

                .user-avatar {
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-left: 4px;
                }

                .el-icon-caret-bottom {
                    cursor: pointer;
                    position: absolute;
                    right: -20px;
                    top: 25px;
                    font-size: 12px;
                }
            }
        }
    }
    
    .home-link{
        float: left;
        margin-right: 10px;
    }
    
    .home-link.active{
        background: #EBF5FE;
    }
    
    .home-link.active .home-ico{
        color: #409eff;
    }
    
    .home-ico{
        float: left;
        margin: 16px 30px 23px 34px;
        font-size: 26px;
        color: #C0C4CC;
    }
    
    .logout-btn{
        font-size: 26px;
        float: right;
        margin-top: 19px;
        margin-right: 20px;
        cursor: pointer;
    }
}
</style>
