<template>
    <div class="upload-file">
        <el-upload action="#" class="upload-file-uploader" ref="upload"
            :disabled="disabled"
            :before-upload="handleBeforeUpload"
            :file-list="fileList"
            :limit="limit"
            :on-error="handleUploadError"
            :on-exceed="handleExceed"
            :show-file-list="false"
            :headers="headers"
            :drag="drag"
            :http-request="handleUpload"
            v-if="fileList.length < limit"
        >
            <i class="el-icon-plus upload-plus" v-if="drag"></i>
    
            <!-- 上传按钮 -->
            <el-button size="mini" type="primary" :disabled="disabled" v-if="!drag">选取文件</el-button>
            <!-- 上传提示 -->
            <div class="el-upload__tip" slot="tip" v-if="showTip">
                请上传
                <template v-if="fileSize">
                    大小不超过 <b>{{ fileSize }}MB</b>
                </template>
                <template v-if="fileType">
                    格式为 <b>{{ fileType.join('/') }}</b>
                </template>
                的文件
                <template v-if="drag">
                    <br><b>点击按钮</b>或<b>拖拽文件</b>到本区域上传
                </template>
            </div>
        </el-upload>
    
        <template v-if="isVideo">
            <template v-for="(file, index) in fileList">
                <div class="video-box">
                    <el-button type="danger" icon="el-icon-close" circle @click="handleDelete(index)" size="mini"></el-button>
                    <video style="width:360px;" controls="controls">
                        <source :src="file.url" type="video/mp4" />
                        您的浏览器暂不支持视频播放
                    </video>
                </div>
            </template>
        </template>

        <!-- 文件列表 -->
        <transition-group class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul" v-else>
            <li :key="file.uid" class="el-upload-list__item ele-upload-list__item-content" v-for="(file, index) in fileList">
                <el-link :href="`${baseUrl}${file.url}`" :underline="false" target="_blank">
                    <span class="el-icon-document"> {{ getFileName(file.name) }} </span>
                </el-link>
                <div class="ele-upload-list__item-content-action">
                    <el-link :underline="false" @click="handleDelete(index)" type="danger">删除</el-link>
                </div>
            </li>
        </transition-group>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth';

const OSS    = require('ali-oss');
const client = new OSS({
    region: 'oss-cn-guangzhou',
    accessKeyId: 'LTAI5tCs7UJuQcBnDqrE9qiA',
    accessKeySecret: 'k3Rp4oBDKR2zIosIKTOF9vCNJhshJE',
    bucket: 'xygallery'
});

export default {
    name: 'FileUpload',
    props: {
        // 值
        value: [String, Object, Array],
        // 数量限制
        limit: {
            type: Number,
            default: 1,
        },
        // 大小限制(MB)
        fileSize: {
            type: Number | String,
            default: 5,
        },
        // 文件类型, 例如['png', 'jpg', 'jpeg']
        fileType: {
            type: Array,
            default: () => ['doc', 'xls', 'ppt', 'txt', 'pdf'],
        },
        // 是否显示提示
        isShowTip: {
            type: Boolean,
            default: true,
        },
        // 能否拖动上传
        drag: {
            type: Boolean,
            default: false,
        },
        disabled: Boolean,
    },
    data() {
        return {
            baseUrl: 'localhost',
            uploadFileUrl: process.env.VUE_APP_BASE_API + '/admin/ajax/upload', // 上传的图片服务器地址
            headers: {
                token: getToken(),
            },
            fileList: [],
            isVideo: false,
        }
    },
    watch: {
        value: {
            handler(val) {
                if (val) {
                    const temp = 1
                    // 首先将值转为数组
                    const list = Array.isArray(val) ? val : this.value.split(',')
                    // 然后将数组转为对象数组
                    this.fileList = list.map(item => {
                        if (typeof item === 'string') {
                            item = {
                                name: item,
                                url: item,
                                uid: new Date().getTime(), // + temp++
                            }
                        }

                        return item
                    })
                } else {
                    this.fileList = []
                    return []
                }
            },
            deep: true,
            immediate: true,
        },
        fileType: {
            handler(val) {

                // 判断文件是否为视频
                if( this.fileType.includes('mp4') ){
                    this.isVideo = true;
                }

            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        // 是否显示提示
        showTip() {
            return this.isShowTip && (this.fileType || this.fileSize)
        },
    },
    methods: {
        // 上传前校检格式和大小
        handleBeforeUpload(file) {
            // 校检文件类型
            if (this.fileType) {
                let fileExtension = '';
                if (file.name.lastIndexOf('.') > -1) {
                    fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
                }
                const isTypeOk = this.fileType.some(type => {
                    if (file.type.indexOf(type) > -1) return true;
                    if (fileExtension && fileExtension.indexOf(type) > -1) return true;
                    return false;
                })
                if (!isTypeOk) {
                    this.$message.error(`文件格式不正确, 请上传${this.fileType.join('/')}格式文件!`);
                    return false;
                }
            }
            // 校检文件大小
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize;
                if (!isLt) {
                    this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
                    return false;
                }
            }
            
            this.loading = this.$loading({
                lock: true,
                text: '上传中',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            
            return true;
        },
        // 文件个数超出
        handleExceed() {
            this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`)
        },
        // 上传失败
        handleUploadError(err) {
            this.$message.error('上传失败, 请重试')
        },
        // 自定义上传(阿里云)
        handleUpload(option){

            let file = option.file;
            let ext  = file.name.slice(file.name.lastIndexOf('.') + 1);
            let name = Math.random().toString(36).substring(2) + '.' + ext;

            const progress = (p, cpt) => {
                // 分片上传的断点信息 checkpoint
                // console.log(cpt);

                // Object的上传进度, 1代表完成
                // console.log(p);
                if( p==1 ){

                    this.fileList.push({
                        name: name,
                        url: process.env.VUE_APP_ALIOSS + name,
                        uid: new Date().getTime(),
                    });
                    
                    // 返回 v-model的参数（关键）
                    this.$emit('input', this.listToString(this.fileList));
                    this.loading.close();
                    this.$message.success('上传成功');
                }
            }

            // 开始分片上传。
            async function multipartUpload(name, file) {

                try {
                    return await client.multipartUpload(name, file, {
                        progress,
                    });
                } catch (e) {
                    // 捕获超时异常。
                    if (e.code === 'ConnectionTimeoutError') {
                        console.log('TimeoutError');
                    }
                    console.log(e);
                }
            }

            multipartUpload(name, file);
        },
        // 删除文件
        handleDelete(index) {
            // 禁止操作时也禁止删除
            if( this.disabled ){
                return true;
            }
            this.fileList.splice(index, 1)
            this.$emit('uploadInput', this.listToString(this.fileList))
        },
        // 获取文件名称
        getFileName(name) {
            if (name.lastIndexOf('/') > -1) {
                return name.slice(name.lastIndexOf('/') + 1).toLowerCase()
            } else {
                return ''
            }
        },
        // 对象转成指定字符串分隔
        listToString(list, separator) {
            let strs = ''
            separator = separator || ','
            for (const i in list) {
                strs += list[i].url + separator
            }
            return strs != '' ? strs.substr(0, strs.length - 1) : ''
        },
    },
}
</script>

<style scoped lang="scss">
.upload-file-uploader {
    margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
    border: 1px solid #e4e7ed;
    line-height: 2;
    margin-bottom: 10px;
    position: relative;
}
.upload-file-list .ele-upload-list__item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: inherit;
}
.ele-upload-list__item-content-action .el-link {
    margin-right: 10px;
}
.video-box{
    position: relative;
}
.video-box .el-button{
    position: absolute;
    right: 10px;
    top: 0;
}
.video-box video{
    width: 360px;
}
.upload-plus{
    font-size: 28px;
    margin-top: 76px;
    color: #8c939d;
}

.el-upload__tip b{
    color: #f56c6c;
}
</style>
