<template>
    <div v-html="value" ref="htmlBox">
    </div>
</template>

<script>
export default {
    name: 'QuickAdminHtml',
    props: {
        value: {
            required: true,
            type: String,
        },
    },
    mounted() {
        let obj = this.$refs.htmlBox;
        if( obj.firstChild ){
            let hoverBox = obj.firstChild;
            if( hoverBox.className && hoverBox.className.includes('hover-show')!==false ){
                hoverBox.addEventListener('mouseover', (event) => {
                    if( event.target.lastChild.style ){
                        let arr = event.target.getBoundingClientRect()
                        event.target.lastChild.style.display = 'block';
                        event.target.lastChild.style.top = arr.y + arr.height + 'px'
                    }
                });
                hoverBox.addEventListener('mouseleave', (event) => {
                    if( event.target.lastChild.style ){
                        event.target.lastChild.style.display = 'none';
                    }
                });
            }
        }
    }
}
</script>

<style>
.hover-show{
    text-align: left;
    position: relative;
}
.hover-show .hide{
    display: none;
    z-index: 9;
    position: fixed;
    background: #eee;
    padding: 10px;
    max-width: 300px;
}
.scroll-Y{
    max-height: 100px;
    overflow-y: scroll;
}
.scroll-Y::-webkit-scrollbar{
    width: 5px;
}
.scroll-Y::-webkit-scrollbar-thumb{
    border-radius: 5px;
    background: #b5c2d1;
}
.scroll-Y::-webkit-scrollbar-track{
    border-radius: 0;
    background: #ececec;
}
</style>
