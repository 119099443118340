var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "component-mult-input" },
    [
      _c(
        _vm.dragToSort ? "draggable" : "div",
        {
          tag: "component",
          class: _vm.dragToSort ? "draggable" : "",
          on: {
            start: function ($event) {
              _vm.isDragging = true
            },
            end: function ($event) {
              _vm.isDragging = false
            },
          },
          model: {
            value: _vm.list,
            callback: function ($$v) {
              _vm.list = $$v
            },
            expression: "list",
          },
        },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "el-row",
            [
              _vm.params.hideKey
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item.key,
                        expression: "item.key",
                      },
                    ],
                    attrs: { type: "hidden" },
                    domProps: { value: item.key },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(item, "key", $event.target.value)
                      },
                    },
                  })
                : _c(
                    "el-col",
                    {
                      staticStyle: { "padding-right": "10px" },
                      attrs: { span: 8 },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.params.keyDesc },
                        model: {
                          value: item.key,
                          callback: function ($$v) {
                            _vm.$set(item, "key", $$v)
                          },
                          expression: "item.key",
                        },
                      }),
                    ],
                    1
                  ),
              _c(
                "el-col",
                {
                  staticStyle: { "padding-right": "10px" },
                  attrs: { span: 10 },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.params.valDesc,
                      disabled: _vm.params.valDisabled,
                    },
                    model: {
                      value: item.val,
                      callback: function ($$v) {
                        _vm.$set(item, "val", $$v)
                      },
                      expression: "item.val",
                    },
                  }),
                ],
                1
              ),
              !_vm.params.detail
                ? [
                    !_vm.params.detail
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "error",
                                plain: "",
                                icon: "el-icon-delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.delItem(item, index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : [
                    _c("br"),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c("ImageUpload", {
                          attrs: { drag: true },
                          model: {
                            value: item.image,
                            callback: function ($$v) {
                              _vm.$set(item, "image", $$v)
                            },
                            expression: "item.image",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入作者简介",
                            rows: 4,
                            type: "textarea",
                          },
                          model: {
                            value: item.desc,
                            callback: function ($$v) {
                              _vm.$set(item, "desc", $$v)
                            },
                            expression: "item.desc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              plain: "",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.delItem(item, index)
                              },
                            },
                          },
                          [_vm._v("删除嘉宾")]
                        ),
                      ],
                      1
                    ),
                    _c("el-divider"),
                  ],
            ],
            2
          )
        }),
        1
      ),
      _vm.params.url
        ? _c("el-autocomplete", {
            attrs: {
              "fetch-suggestions": _vm.querySearchAsync,
              placeholder: _vm.params.placeholder,
            },
            on: { select: _vm.handleSelect, blur: _vm.handleBlur },
            nativeOn: {
              keyup: function ($event) {
                return _vm.handleKeyup($event)
              },
            },
            model: {
              value: _vm.search_title,
              callback: function ($$v) {
                _vm.search_title = $$v
              },
              expression: "search_title",
            },
          })
        : _c("el-button", {
            attrs: {
              size: "mini",
              type: "primary",
              plain: "",
              icon: "el-icon-plus",
            },
            on: {
              click: function ($event) {
                return _vm.addItem()
              },
            },
          }),
      _vm.dragToSort
        ? _c("b", { staticClass: "drag-to-sort" }, [
            _vm._v("按住可进行拖拽排序"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }