<template>
    <div>
        <span>{{value.text}}</span>
        <el-tooltip effect="dark" content="重置人数">
            <i id="icon" class="el-icon-refresh-left my-icon" @click="reset($event, value)"></i>
        </el-tooltip>
    </div>
</template>

<script>
    export default {
        name: 'Custom',
        props: {
            value: {
                required: true,
                type: Object,
            },
        },
        data(){
            return {
                text: '',
            }
        },
        methods: {
            reset(event, val) {

                this.$confirm('请确认是否重置', '警告', {type: 'warning'}).then(() => {
                    let data = {
                        key: val.key,
                        val: '',
                    }
                    this.$emit('customChange', data);
                }).catch(() => {
                    console.log('用户取消');
                });
            },
        },
    }
</script>

<style rel="scss" scoped>
.my-icon{
    cursor: pointer;
    color: #409eff;
    margin-left: 4px;
    font-size: 16px;
}
</style>
