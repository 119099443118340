var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-switch", {
        attrs: {
          value: _vm.status,
          "active-value": _vm.activeValue,
          "inactive-value": _vm.inactiveValue,
          "active-text": _vm.activeText,
          "inactive-text": _vm.inactiveText,
        },
        on: { change: _vm.statusChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }